<template>
    <div class>
        <div class="text-gray-400 title text-left duration-500 pc_show">中奖纪录</div>
        <div class="content sm:px-2">
            <div
                class="flex justify-between items-center item py-3"
                v-for="item in recordList"
                :key="item.luckDrawRecordId"
            >
                <div class="flex">
                    <div
                        style="overflow:hidden;"
                        class="p-2 flex justify-center items-center img_box"
                    >
                        <!-- <img src="../../assets/images/award/record2.png" alt /> -->
                        <img :src="item.prizeUrl" alt />
                    </div>
                    <div class="pl-1 text-left pt-2">
                        <div class="text-base font-bold">抽奖获得{{ item.prizeName }}</div>
                        <!-- <div class="text-primary">
                            价值
                            <span class="text-lg font-bold">￥9999</span>
                        </div>-->
                        <div class="text-sm text-gray-500">{{ item.createTime }}</div>
                    </div>
                </div>
                <div class="flex justify-center items-center">
                    <div
                        class="text-xs text-white px-2 rounded-sm"
                        :class="item.status == 1 || item.status == 2 ? 'bg-primary' : 'bg-gray-300'"
                        style="padding-top:5px;padding-bottom:5px "
                        @click="tapItem(item)"
                    >{{ item.status == 1 ? '马上领取' : item.status == 2 && item.type == 2 ? '已领取，查看物流' : item.status == 2 && item.type == 1 ? '已领取' : item.status == 3 ? "已收货" : "已过期" }}</div>
                </div>
            </div>
            <div class="py-4" v-if="maxPage > 1 && page != maxPage">
                <div class="text-gray-500" @click="loadMore">-- 点击查看更多 --</div>
            </div>
        </div>
        <Popup :isShow="showPopup" @close="closeSearch">
            <template>
                <div class="p-2">
                    <div class="font-bold text-center text-base mb-4">选择收货地址</div>
                    <div class="pc_select_show">
                        <el-select
                            v-model="address"
                            placeholder="请选择"
                            style="width:100%;max-width: 400px;"
                        >
                            <el-option
                                v-for="item in getAddressList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="phone_select_show">
                        <div
                            class="form_input custom_input flex"
                            @click="openPopup"
                            style="width:100%"
                        >
                            <span
                                :class="address == '' ? 'text-gray-300' : ''"
                                class="address_span"
                            >
                                {{
                                    address == "" ? "请选择" : tramsfromAddress(address)
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="mt-4">
                        <el-button
                            type="primary"
                            class="btn duration-500"
                            @click="lotteryReceive"
                        >马上领取</el-button>
                    </div>
                </div>
            </template>
        </Popup>
        <MobilePopup ref="mobilepopup" :list="getAddressList" @select="selectFunc"></MobilePopup>
    </div>
</template>

<script>
import Popup from "@/components/Popup/Popup"
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            page: 1,
            pageSize: 20,
            maxPage: 1,
            recordList: [],
            address: "",
            showPopup: false,
            selectPrize: {}
        };
    },
    components: {
        Popup
    },
    computed: {
        ...mapGetters(['getAddressList'])
    },
    created() {
        this.lotteryRecord()
        this.$store.dispatch("aquireAddress")
    },
    mounted() {
        localStorage.removeItem("selectPrize")
    },
    methods: {
        // 关闭弹窗
        closeSearch() {
            this.showPopup = false
        },
        openPopup() {
            this.$refs.mobilepopup.open();
        },
        selectFunc(item) {
            this.address = item.value
        },
        tramsfromAddress(address) {
            let index = this.getAddressList.findIndex(item => {
                return item.value == address
            })
            return this.getAddressList[index].label
        },
        goBack() {
            this.$router.go(-1)
        },
        // 抽奖纪录
        lotteryRecord() {
            this.$request({
                method: "get",
                url: this.$requestPath.lotteryRecord,
                params: {
                    currPage: this.page,
                    pageSize: this.pageSize
                }
            }).then(res => {
                console.log("获取抽奖纪录", res)
                if (res.code == 0) {
                    if (this.page == 1) {
                        this.recordList = []
                    }
                    this.recordList.push(...res.data.list)
                    this.maxPage = res.data.totalCount / this.pageSize
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取抽奖纪录error", error)
            })
        },
        // 加载更多
        loadMore() {
            this.page = this.page + 1
            this.lotteryRecord()
        },
        // 点击按钮
        tapItem(item) {
            this.selectPrize = Object.assign({}, this.selectPrize, item)
            if (item.status == 4) {
                return
            }
            switch (item.status) {
                case 1:
                    if (item.type == 2) {
                        this.showPopup = true
                    }
                    if (item.type == 1) {
                        this.lotteryReceive()
                    }
                    break;
                case 2:
                    if (item.type == 2) {
                        localStorage.setItem("selectPrize", JSON.stringify(item))
                        this.$router.push({ path: `/index/sales/logistics?maintainId=${item.luckDrawRecordId}&kdType=4` })
                    }
                // localStorage.setItem("selectPrize", JSON.stringify(item))
                // this.$router.push({ path: `/index/sales/logistics?maintainId=${item.luckDrawRecordId}&kdType=4` })


            }


        },
        // 领取奖品
        lotteryReceive() {
            let params = {
                luckDrawRecordId: this.selectPrize.luckDrawRecordId
            }
            if (this.selectPrize.type == 2) {
                if (this.isNull(this.address)) {
                    // this.$message.error('密保问题答案不能为空');
                    this.$EventBus.$emit("toast", { type: 'error', message: '收货地址不能为空' })
                    return
                }
                params.address = this.address
            }
            this.$request({
                method: "get",
                url: this.$requestPath.lotteryReceive,
                params
            }).then(res => {
                console.log("获取抽奖纪录", res)
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: "领取成功" })
                    let index = this.recordList.findIndex(item => {
                        return item.luckDrawRecordId == this.selectPrize.luckDrawRecordId
                    })
                    setTimeout(() => {
                        this.recordList[index].status = 2
                        if (this.selectPrize.type == 2) {
                            this.showPopup = false
                        }
                    }, 1000);
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取抽奖纪录error", error)
            })
        }

    },
};
</script>


<style lang='scss' scoped>
.navbar {
    box-sizing: border-box;
    border-bottom: 0.0625rem solid var(--borderLightColor);
}
.item {
    border-bottom: 0.0625rem solid var(--borderLightColor);
}
@media screen and (max-width: 640px) {
    .img_box {
        height: 5.625rem;
        width: 5.625rem;
    }
    .pc_show {
        display: none;
    }
}
@media screen and (min-width: 640px) {
    .img_box {
        height: 7.5rem;
        width: 7.5rem;
    }
    .title {
        font-size: 1.875rem;
    }
    .pc_show {
        display: block;
    }
}
</style>